import React from 'react';
import PropTypes from 'prop-types';

import './Button.scss';
import Spinner from '../Spinner';

const ButtonDefault = ({
  isLoading,
  disabled,
  className,
  handleClick,
  children,
  fullWidth,
  tabIndex,
}) => (
  <button
    className={`button ${className} ${fullWidth ? 'full-width' : ''}`}
    disabled={disabled || isLoading}
    onClick={handleClick}
    tabIndex={tabIndex}
  >
    <Spinner hide={!isLoading} />
    { children }
  </button>
);

const ButtonSubmit = ({
  isLoading,
  disabled,
  className,
  children,
  fullWidth,
  tabIndex,
}) => (
  <button
    className={`button ${className} ${fullWidth ? 'full-width' : ''}`}
    disabled={disabled || isLoading}
    type="submit"
    tabIndex={tabIndex}
  >
    <Spinner hide={!isLoading} />
    { children }
  </button>
);

const Button = (props) => (
  props.type === 'submit'
    ? <ButtonSubmit {...props} />
    : <ButtonDefault {...props} />
);

ButtonDefault.propTypes = {
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tabIndex: PropTypes.number,
};

ButtonDefault.defaultProps = {
  className: '',
  disabled: false,
  fullWidth: false,
  isLoading: false,
};

ButtonSubmit.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  tabIndex: PropTypes.number,
};

ButtonSubmit.defaultProps = {
  className: '',
  disabled: false,
  fullWidth: false,
  isLoading: false,
};

Button.defaultProps = {
  type: 'button',
  tabIndex: 0,
};

export default Button;
