import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

const Spinner = ({ hide }) => (
  !hide && <div className="spinner">
    <div><p className="paragraph" /></div>
  </div>
);

Spinner.propTypes = {
  hide: PropTypes.bool.isRequired,
};

export default Spinner;
