import React, { useRef, useState, useEffect, useContext }  from 'react';

import '../styles/index.scss';
import { AppContext } from '../context/AppContext';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import useRect from '../hooks/useRect';
import About from '../components/About';
import TopBlock from '../components/TopBlock';
import Contact from '../components/Contact';
import Services from '../components/Services';
import Career from '../components/Career';

/* Sync with css breakpoints */
const BREAKPOINT_SM = 600; // eslint-disable-line no-unused-vars
const BREAKPOINT_MD = 960;

const IndexPage = () => {
  const [{ reduceMotion }] = useContext(AppContext);
  const [topSectionHeight, setTopSectionHeight] = useState(0);
  const [innerWidth, setInnerWidth] = useState(null);
  const isSmallScreen = (innerWidth && (innerWidth < BREAKPOINT_MD)) || false;
  const ref = useRef();

  /* Set initial height once */
  useEffect(() => {
    const rect = ref.current.getBoundingClientRect()
    setTopSectionHeight(rect.height)
    setInnerWidth(rect.width)
  }, []);

  /* Listen to rezise */
  useRect(
    ({ curr: { height, width } }) => {
      if (topSectionHeight !== height) {
        setTopSectionHeight(height);
      }
      if (innerWidth !== width) {
        setInnerWidth(width);
      }
    }, [ref, topSectionHeight, innerWidth], ref, 500,
  );
  
  return (
    <div>
      <TopBlock ref={ref} reduceMotion={reduceMotion} />
      <Layout topSectionHeight={topSectionHeight} isSmallScreen={isSmallScreen}>
        <SEO />
        <About id="about-us" reduceMotion={reduceMotion} />
        <Services id="services" reduceMotion={reduceMotion} />
        <Career id="career" reduceMotion={reduceMotion} />
        <Contact id="contact" reduceMotion={reduceMotion} />
      </Layout>
    </div>
  );
};

export default IndexPage;
