import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';

import './Contact.scss';
import PageSection from '../PageSection';
import { Title, Paragraph, ParagraphBlock, FloatingTitle } from '../Typography';
import Grid from '../Grid';
import Button from '../Button';
import { AppContext } from '../../context/AppContext';

const initialStatus = { error: false, success: false, message: '' };
const submitSuccessMessage = 'Tack! Vi återkopplar så snart som möjligt';
const submitErrorMessage = 'Oops! Något gick fel. Var vänlig försök igen.';

const Contact = ({ id, reduceMotion }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { path: { eq: "/content/contact-block" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const nodes = data
    ? data.allMarkdownRemark.edges.map(e => e.node)
    : [];

  const blockContent = nodes.length > 0
    ? nodes.find(n => n.frontmatter.path === '/content/contact-block')
    : { html: '', frontmatter: { title: '' } };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState(initialStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [{ menuOpen }] = useContext(AppContext);

  const disable = () => (
    (name.length < 3 || message.length < 10) || // eslint-disable-next-line no-useless-escape
    email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null
  );
  useEffect(() => {
    const timer = setTimeout(() => setStatus(initialStatus), 5 * 1000);
    return () => clearTimeout(timer);
  }, [status.message])

  const handleSubmit = e => {
    e.preventDefault();
    document.activeElement.blur();
    setIsLoading(true);
    const data = new FormData(e.target);
    fetch('https://formspree.io/maydkvzb', {
      method: 'POST',
      headers: {
        'Accept': 'appplication/json'
      },
      body: data
    })
    .then(res => res.json())
    .then((data) => {
      if (data.ok) {
        setStatus({ error: false, success: true, message: submitSuccessMessage });
        setName('');
        setEmail('');
        setMessage('');
      } else if (data.error) {
        setStatus({ error: true, success: false, message: submitErrorMessage });
      }
    })
    .catch(e => {
      setStatus({ error: true, success: false, message: submitErrorMessage });
      console.error(e);
    }).finally(() =>  setIsLoading(false));
  };

  return (
    <>
      <span id={id} className="scroll-to"></span>
      <PageSection className="contact-section" sectionId={`section-${id}`}>
        <Grid row classes="content-wrapper">
          <Grid xs={12}>
            <Parallax y={[50, -25]} disabled={reduceMotion}>
              <FloatingTitle className="text-right">Kontakt</FloatingTitle>
            </Parallax>
          </Grid>
        </Grid>
        <Grid row gutters classes="flex-wrap content-wrapper">
          <Grid xs={12} md={7} classes="content">
            <Grid row classes="flex-column space-between">
              <Grid xs={12}>
                <ParagraphBlock>
                  <Title dot={false} ask>{ blockContent.frontmatter.title }</Title>
                </ParagraphBlock>
              </Grid>
              <Grid xs={12}>
                <ParagraphBlock>
                  <div dangerouslySetInnerHTML={{ __html: blockContent.html }} />
                </ParagraphBlock>
              </Grid>
              <Grid xs={12}>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    placeholder="för- &amp; efternamn"
                    tabIndex={menuOpen ? -1 : 0}
                    aria-hidden={menuOpen}
                    />
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="e-postadress"
                    tabIndex={menuOpen ? -1 : 0}
                    aria-hidden={menuOpen}
                    />
                  <textarea
                    name="message"
                    placeholder="meddelande"
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    rows={4}
                    tabIndex={menuOpen ? -1 : 0}
                    aria-hidden={menuOpen}
                    />
                  <Grid row classes="space-between flex-align-center">
                    <p className={`text-sm paragraph ${status.success ? 'text-success' : 'text-error'}`}>
                      { status.message }
                    </p>
                    <Button
                      className="btn-alcesys"
                      disabled={disable()}
                      isLoading={isLoading}
                      type="submit"
                    >
                      <Paragraph>skicka</Paragraph>
                    </Button>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={5} classes="map-wrapper">
            <iframe
              tabIndex={menuOpen ? -1 : 0}
              aria-hidden={menuOpen}
              frameBorder="0"
              title="map"
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJB7-0CfB3X0YRtXaC0VnXm94&key=AIzaSyCGpjWo8sp8AxYGZO-WvPz8v2IrDyEiHjs"
              allowFullScreen
              />
          </Grid>
        </Grid>
      </PageSection>
    </>
  );
};

Contact.propTypes = {
  id: PropTypes.string.isRequired,
  reduceMotion: PropTypes.bool.isRequired,
};

export default Contact;
