import React from 'react';

const MainWave = () => (
  <svg viewBox="0 0 1024 258" preserveAspectRatio="xMinYMin meet">
    <defs>
      <filter
        x="-2%"
        y="-11.9%"
        width="104%"
        height="123.8%"
        filterUnits="objectBoundingBox"
        id="prefix__a_main"
      >
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={17.5}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0.175243433 0 0 0 0 0.175243433 0 0 0 0 0.175243433 0 0 0 0.10079764 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path
        d="M793.728 366.747c107.432 29.985 214.227 44.978 320.384 44.978 159.235 0 183.007-44.978 328.964-44.978s146.088 44.978 301.407 44.978c155.32 0 139.89-44.978 301.146-44.978 161.256 0 163.356 44.978 334.014 44.978 170.66 0 154.756-44.978 329.816-44.978 175.06 0 213.724 44.978 373.8 44.978 106.716 0 205.54-14.993 296.47-44.978l27.699 441.286h-2586l-27.7-441.286z"
        id="prefix__b_main"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M-1484.03 290.815c110.286 16.669 218.112 18.535 323.478 5.598 158.048-19.406 176.161-66.946 321.03-84.734 144.87-17.788 150.48 26.84 304.642 7.91 154.162-18.928 133.366-61.69 293.42-81.343 160.054-19.652 167.62 24.735 337.006 3.937 169.387-20.798 148.12-63.503 321.876-84.837 173.754-21.334 217.612 18.596 376.494-.912 105.922-13.005 202.181-39.93 288.779-80.773l81.273 434.621-2566.725 315.154-81.272-434.621z"
        fill="#42424D"
      />
      <path
        d="M-962.774 259.077c110.286 16.669 218.111 18.535 323.477 5.598 158.049-19.406 176.162-66.946 321.031-84.734 144.87-17.788 150.48 26.84 304.642 7.91 154.162-18.928 133.365-61.69 293.42-81.343 160.053-19.652 167.619 24.735 337.006 3.937 169.387-20.798 148.12-63.503 321.875-84.837 173.755-21.334 217.613 18.596 376.495-.912 105.921-13.005 202.18-39.93 288.779-80.773l81.272 434.621L-881.5 693.698l-81.273-434.621z"
        fill="#3A3A45"
      />
      <path
        d="M-791.75 300.734c110.285 16.67 218.11 18.536 323.476 5.598 158.049-19.406 176.162-66.946 321.031-84.733 144.869-17.788 150.48 26.839 304.642 7.91 154.161-18.928 133.365-61.69 293.42-81.343 160.053-19.652 167.619 24.735 337.006 3.937C957.212 131.305 935.945 88.6 1109.7 67.266c173.755-21.335 217.613 18.596 376.495-.912 105.921-13.006 202.18-39.93 288.779-80.774l81.272 434.622-2566.724 315.154-81.273-434.622z"
        fill="#E9A028"
      />
      <g transform="rotate(-7 -362.38 12517.817)">
        <use fill="#000" filter="url(#prefix__a_main)" xlinkHref="#prefix__b_main" />
        <use fill="#FFF" xlinkHref="#prefix__b_main" />
      </g>
    </g>
  </svg>
);

export default MainWave;
