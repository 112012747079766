import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';

import './Services.scss';
import { Title, FloatingTitle, ParagraphBlock } from '../Typography';
import Grid from '../Grid';
import SwipeSection from '../SwipeSection';
import SvgContainer from '../SvgContainer';
import Wave from './service-wave-raw.svg';

const Services = ({ id, reduceMotion }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { path: { in: [
        "/content/services-block-1",
        "/content/services-block-2",
        "/content/services-block-3",
        "/content/services-block-4",
      ] } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const nodes = data
    ? data.allMarkdownRemark.edges.map(e => e.node)
    : [];
  
  const sectionId = `section-${id}`
  return (
    <>
      <span id={id} className="scroll-to"></span>
      <section className="page-section">
      <div className="services-section text-light">
        <div className="content">
            <Grid row classes="content-wrapper">
              <Grid xs={12}>
                <Parallax y={[50, -25]} disabled={reduceMotion}>
                  <FloatingTitle className="text-right">Tjänster</FloatingTitle>
                </Parallax>
              </Grid>
            </Grid>
            <div className="content-wrapper">
              { nodes.map((n, i) => (
                <SwipeSection key={n.id} right={i % 2 > 0} sectionId={sectionId} className={`${i % 2 > 0 ? 'text-right-sm-up': ''}`}>
                  <Grid row classes={`${i % 2 > 0 ? 'justify-end': ''}`}>
                    <Grid xs={12} md={8}>
                      <Title>{ n.frontmatter.title || '' }</Title>
                      <ParagraphBlock>
                        <div dangerouslySetInnerHTML={{ __html: n.html || '' }} />
                      </ParagraphBlock>
                    </Grid>
                  </Grid>
                </SwipeSection>
              ))}
            </div>
          </div>
          <SvgContainer viewBoxRatio={174 / 1024}>
            <img src={Wave} alt="" />
          </SvgContainer>
        </div>
      </section>
    </>
  );
};

Services.propTypes = {
  id: PropTypes.string.isRequired,
  reduceMotion: PropTypes.bool.isRequired,
};

export default Services;
