import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import './SwipeSection.scss';
import { AppContext } from '../../context/AppContext';

const SwipeSection = ({ children, className, right, sectionId }) =>  {
  const [{ activeMenuItem, handleActiveMenuItem}] = useContext(AppContext);
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();
  useEffect(() => {
    const domRefToClean = domRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        setVisible(isIntersecting);
        if (isIntersecting && (activeMenuItem !== sectionId)) {
          handleActiveMenuItem(sectionId);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRefToClean);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`swipe-section ${right ? 'swipe-right' : 'swipe-left'} ${isVisible ? 'is-visible' : ''} ${className}`}
      ref={domRef}
    >
      { children }
    </div>
  );
};

SwipeSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

SwipeSection.defaultProps = {
  className: '',
};

export default SwipeSection;
