import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';

import './TopBlock.scss';
import { Heading, Paragraph } from '../Typography';
import Grid from '../Grid';
import MainWaveSvg from './MainWaveSvg';
import Button from '../Button';
import SvgContainer from '../SvgContainer';
import { AppContext } from '../../context/AppContext';

const TopBlock = React.forwardRef((props, ref) => {
  const [{ navigate, menuOpen }] = useContext(AppContext);
  const { reduceMotion } = props;

  const handleServicesClick = () => {
    document.activeElement.blur();
    navigate('services');
  };

  return (
    <section
      id="top"
      className="top-block"
      ref={ref}
    >
      <Parallax y={[75, -75]} styleOuter={{ zIndex: 1 }} disabled={reduceMotion}>
        <div className="content">
          <Grid row classes="flex-wrap">
            <Grid xs={12}>
              <Heading className="text-light">
                Konsulter inom system- och produktutveckling
              </Heading>
            </Grid>
            <div>
              <Button tabIndex={menuOpen ? -1 : 0} handleClick={handleServicesClick} className="btn-alcesys btn-lg">
                <Paragraph>våra tjänster!</Paragraph>
              </Button>
            </div>
          </Grid>
        </div>
      </Parallax>
      <SvgContainer viewBoxRatio={258 / 1024}>
        <MainWaveSvg />
      </SvgContainer>
    </section>
  )}
);

TopBlock.propTypes = {
  reduceMotion: PropTypes.bool.isRequired,
};

export default TopBlock;
