import React from 'react'
import PropTypes from 'prop-types'

import './PageSection.scss';
import FadeInSection from '../FadeInSection';

const PageSection = ({ children, className, sectionId }) => (
  <section className="page-section">
    <FadeInSection className={className} sectionId={sectionId}>
      { children }
    </FadeInSection>
  </section>
);

PageSection.propTypes = {
  sectionId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PageSection.defaultProps = {
  className: '',
};

export default PageSection
