import { useRef, useLayoutEffect } from 'react';

const isBrowser = typeof window !== `undefined`;

const getRect = ({ element }) => {
  if (!isBrowser) return { height: 0, width: 0 };
  const target = element.current || document.body;
  const position = target.getBoundingClientRect();
  return position;
};

const useRect = (effect, deps, element, wait) => {
  const rect = useRef(getRect({ element }))
  let throttleTimeout = null;

  const callBack = () => {
    const currRect = getRect({ element });
    effect({ prev: rect.current, curr: currRect });
    rect.current = currRect;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleResize = () => {
      if (wait) {
        if (throttleTimeout === null) {
          throttleTimeout = setTimeout(callBack, wait); // eslint-disable-line react-hooks/exhaustive-deps
        }
      } else {
        callBack();
      }
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [...deps, callBack, wait]);
};

export default useRect;
