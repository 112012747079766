import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';

import './Career.scss';
import PageSection from '../PageSection';
import { Title, FloatingTitle, ParagraphBlock, Paragraph } from '../Typography';
import Grid from '../Grid';
import Button from '../Button';
import { AppContext } from '../../context/AppContext';
import enjoySvg from './enjoy.svg';


const Career = ({ id, reduceMotion }) => {
  const [{ navigate, menuOpen }] = useContext(AppContext);
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { path: { in: ["/content/career-block-1", "/content/career-block-2"] } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const handleContactClick = () => {
    document.activeElement.blur();
    navigate('contact');
  };

  const nodes = data
    ? data.allMarkdownRemark.edges.map(e => e.node)
    : [];

  const introNode = nodes.find(n => n.frontmatter.path === '/content/career-block-1');
  const mainNode = nodes.find(n => n.frontmatter.path === '/content/career-block-2');


  if (!introNode || !mainNode) return null;

  return (
    <>
      <span id={id} className="scroll-to"></span>
      <PageSection className="career-section" sectionId={`section-${id}`}>
        <div className="content-wrapper">
          <Grid row classes="text-right">
            <Grid xs={12}>
              <Parallax disabled={reduceMotion} y={[50, -25]}>
                <FloatingTitle>Karriär</FloatingTitle>
              </Parallax>
            </Grid>
          </Grid>
          <Grid row classes="flex-wrap content">
            <Grid xs={12}>
              <Title exclaim dot={false}>{ introNode.frontmatter.title || '' }</Title>
              <ParagraphBlock>
                <div className="text-block-margin" dangerouslySetInnerHTML={{ __html: introNode.html || '' }} />
              </ParagraphBlock>
            </Grid>
            <Grid xs={12}>
              <Grid row gutters classes="flex-wrap">
                <Grid xs={12} sm={8}>
                  <Grid row classes="flex-column space-between">
                    <ParagraphBlock>
                      <div className="text-block-margin" dangerouslySetInnerHTML={{ __html: mainNode.html || '' }} />
                    </ParagraphBlock>
                    <div>
                      <Button tabIndex={menuOpen ? -1 : 0} handleClick={handleContactClick} className="text-light btn-alcesys btn-lg">
                        <Paragraph>kontakta oss!</Paragraph>
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Grid xs={12} sm={4} classes="img-container">
                  <img src={enjoySvg} alt="decorative illustration" />
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
        </div>
      </PageSection>
    </>
  );
};

Career.propTypes = {
  id: PropTypes.string.isRequired,
  reduceMotion: PropTypes.bool.isRequired,
};

export default Career;
