import React from 'react';
import PropTypes from 'prop-types';

import './SvgContainer.scss';

const SvgContainer = ({ children, viewBoxRatio, classes }) => (
  <div
    className={`svg-container ${classes}`}
    style={{ paddingBottom: `${viewBoxRatio * 100}%`}}
  >
    { children }
  </div>
);

SvgContainer.propTypes = {
  children: PropTypes.node.isRequired,
  viewBoxRatio: PropTypes.number.isRequired,
  classes: PropTypes.string,
};

SvgContainer.defaultProps = {
  classes: '',
};

export default SvgContainer;
