import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import './FadeInSection.scss';
import { AppContext } from '../../context/AppContext';

const FadeInSection = ({ children, className, sectionId }) =>  {
  const [{ activeMenuItem, handleActiveMenuItem}] = useContext(AppContext);
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef();

  useEffect(() => {
    const domRefToClean = domRef.current;
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting, target: { id } }) => {
        setVisible(isIntersecting);
        if (isIntersecting && (id !== activeMenuItem)) {
          handleActiveMenuItem(id);
        }
      });
    });
    observer.observe(domRef.current);
    return () => observer.unobserve(domRefToClean);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id={sectionId}
      className={`fade-in-section ${isVisible ? 'is-visible' : ''} ${className}`}
      ref={domRef}
    >
      { children }
    </div>
  );
};

FadeInSection.propTypes = {
  children: PropTypes.node.isRequired,
  sectionId: PropTypes.string.isRequired,
  className: PropTypes.string,
};

FadeInSection.defaultProps = {
  className: '',
};

export default FadeInSection;

/**
* Docs: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
* Resource: https://dev.to/selbekk/how-to-fade-in-content-as-it-scrolls-into-view-10j4
* Polyfill:  https://github.com/w3c/IntersectionObserver/tree/master/polyfill
**/
