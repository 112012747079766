import React from 'react';
import PropTypes from 'prop-types';
import { Parallax } from 'react-scroll-parallax';
import { useStaticQuery } from 'gatsby';

import './About.scss';
import PageSection from '../PageSection';
import { Title, FloatingTitle, ParagraphBlock } from '../Typography';
import Grid from '../Grid';
import SvgContainer from '../SvgContainer';
import svg from './about-wave.svg';

const About = ({ id, reduceMotion }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { path: { in: ["/content/about-block-1", "/content/about-block-2"] } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const nodes = data
    ? data.allMarkdownRemark.edges.map(e => e.node)
    : [];

  return (
    <>
      <span id={id} className="scroll-to"></span>
      <PageSection className="about-us-section" sectionId={`section-${id}`}>
        <Grid row classes="content-wrapper">
          <Grid xs={12}>
            <Parallax y={[50, -25]} disabled={reduceMotion}>
              <FloatingTitle className="text-right">Om oss</FloatingTitle>
            </Parallax>
          </Grid>
        </Grid>
          <Grid row classes="flex-center content-wrapper">
            <Grid xs={12} classes="content">
                <Grid row>
                  <Grid xs={12} md={10}>
                    { nodes.map((n) => (
                      <div key={n.id} className="text-area">
                        <Title ask dot={false}>{ n.frontmatter.title || '' }</Title>
                        <ParagraphBlock>
                          <div dangerouslySetInnerHTML={{ __html: n.html || '' }} />
                        </ParagraphBlock>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              <div className="wave-wrap">
                <SvgContainer viewBoxRatio={368 / 1024}>
                  <img src={svg} alt="" />
                </SvgContainer>
              </div>
            </Grid>
          </Grid>
      </PageSection>
    </>
  );
};

About.propTypes = {
  id: PropTypes.string.isRequired,
  reduceMotion: PropTypes.bool.isRequired,
};

export default About;
